$header-bg-color: #444;
$header-outline-color: #888;
$header-text-color: #eee;
//$week-bg-color: #444;
$week-outline-color: #444;
//$week-text-color: #eee;

$day-text-color: rgba(0,0,0,0.7);
$day-bg-color: rgba(200,200,200,0.6);
$day-header-border-color: #aaa;
$day-weekend-bg-color: rgba(200,200,200,0.4);
$day-today-bg-color: rgba(100,100,100,0.4);
$day-label-bg-color: rgba(255,255,255,1.0);
$day-label-today-bg-color: #888;
$day-label-today-text-color: #fff;
$day-label-clash-bg-color: #f00;
$day-label-clash-text-color: #fff;

$button-text-color: rgba(255,255,255,0.8);
$button-period-new-bg-color: #6D8E9F;
$button-event-new-bg-color: rgba(80,0,80,0.6);

$day-content-text-color: #fff;

$event-border-color: #888;

$day-header-height: 2.0rem;
$day-header-font-size: 1.2rem;
$schedule-height: 4.0rem;
$period-height: 2.1rem;
$event-height: 1.9rem;


.ui {
    .stuck-container {
        #calendar-header {
            margin-top: 1.1em !important;
        }
    }
    @media only print {
        .sticky  {
            &.fixed {
                top: 0 !important;
                position:relative;
                width: 100% !important;
            }
        }
    }
}

#calendar-header {
    margin-top: 0;
    &.vcal {
        &.vcal-header {
            box-shadow: 0 -1px 0 0 $header-outline-color !important;
            margin-bottom: 0 !important;
            .vcal-key {
                background-color: #fff;
            }
            .vcal-day {
                box-shadow: -1px 0 0 0 $header-outline-color !important;
                background-color: $header-bg-color;
                color: $header-text-color;
                text-align: center;
                font-weight: bold;
                font-size: $day-header-font-size;
                padding: 0.75em !important;
                @media only print {
                    font-weight: bold;
                    color: #000;
                    background: none;
                }
            }
        }
    }
}

#calendar-content {
    margin-top: 0;
    &.vcal {
        box-shadow: none !important;
        .vcal-week {
            box-shadow: 0 -1px 0 0 $week-outline-color !important;
            border-color: $week-outline-color !important;
            border-style: solid !important;
            border-width: 0 1px 1px 0 !important;
            margin-bottom: 15px !important;
            @media only print {
                page-break-inside: avoid;
                box-shadow: none !important;
                // border: none !important;
                margin-bottom: 0 !important;
            }
            .vcal-week-info {
                padding: 0 !important;
                .vcal-week-header {
                    z-index: 1;
                    height: $day-header-height;
                    border-bottom: 1px solid $day-header-border-color;
                    .vcal-label {
                        text-align: right;
                        font-weight: bold;
                        font-size: $day-header-font-size;
                        padding: 0.25rem 0.4rem;
                        white-space: nowrap;
                    }
                }
                .vcal-week-content {
                    padding-right: 1rem;
                    text-align: right;
                    height: calc(100% - #{$day-header-height});
                    position: relative;
                    .vcal-week-event {
                        @media only print {
                            display: none;
                        }
                    }
                    .vcal-week-children {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        padding-right: 1rem;
                        .vcal-week-child {
                            //position: absolute;
                            //bottom: 0;
                            height: $schedule-height;
                            line-height: $schedule-height;
                            @media only screen and (max-width: 767px) {
                                height: 2.8rem;
                                line-height: 2.7rem;
                            }
                        }
                    }
                }
            }
            .vcal-day {
                height: 12rem;
                @media only print {
                    height: 10rem;
                    background: none;
                }
                box-shadow: -1px 0 0 0 $week-outline-color !important;
                padding: 0 !important;
                color: $day-text-color;
                background-color: $day-bg-color;
                @media only screen and (max-width: 767px) {
                    padding: 0 !important;
                    height: 10rem;
                    &:nth-child(n+2) {
                        margin-top: 3px !important;
                        border-top: 1px solid $week-outline-color;
                    }
                    &:nth-last-child(n+2) {
                        border-bottom: 1px solid $week-outline-color;
                    }
                }
                &.vcal-today {
                    background-color: $day-today-bg-color !important;
                }
                &.vcal-weekday {
                    background-color: $day-weekend-bg-color;
                    @media only print {
                        background: none;
                    }
                    .vcal-events {
                        opacity: 0.8;
                    }
                }
                &.vcal-clashes {
                    .vcal-day-header {
                        background-color: $day-label-clash-bg-color !important;
                        .vcal-label {
                            color: $day-label-clash-text-color !important;
                        }
                    }
                }
                &.vcal-today {
                    .vcal-day-header {
                        .vcal-label {
                            color: $day-label-today-text-color;
                        }
                    }
                }
                .vcal-day-header {
                    z-index: 1;
                    height: $day-header-height;
                    border-bottom: 1px solid $day-header-border-color;
                    .vcal-label {
                        text-align: right;
                        font-weight: bold;
                        font-size: $day-header-font-size;
                        padding: 0.25rem 0.4rem;
                        white-space: nowrap;
                    }
                    .vcal-controls {
                        float: left;
                        padding-top: 1px;
                        padding-left: 1px;
                        button {
                            color: $button-text-color;
                            opacity: 0;
                            -webkit-transition: opacity 0.5s ease-in-out;
                            -moz-transition: opacity 0.5s ease-in-out;
                            -ms-transition: opacity 0.5s ease-in-out;
                            -o-transition: opacity 0.5s ease-in-out;
                            transition: opacity 0.5s ease-in-out;
                            padding-left: 1em;
                            padding-right: 1em;
                            &:hover {
                                opacity: 1.0;
                            }
                            &.vcal-new-period {
                                background-color: $button-period-new-bg-color;
                            }
                            &.vcal-new-event {
                                background-color: $button-event-new-bg-color;
                            }
                        }
                        @media only print {
                            display: none;
                        }
                        @media only screen and (min-width:768px) and (max-width: 1023px) {
                            display: none;
                            //float: none;
                        }
                        @media only screen and (max-width:767px) {
                            button {
                                opacity: 1.0;
                            }
                        }
                    }
                }
                .vcal-day-content {
                    height: calc(100% - #{$day-header-height});
                    position: relative;
                    color: $day-content-text-color;
                    .vcal-time {
                        position: absolute;
                        top: -5px;
                        font-size: 10px;
                        color: #444;
                        &.vcal-start {
                            left: 1px;
                        }
                        &.vcal-end {
                            right: 1px;
                        }
                    }
                    .vcal-length {
                        position: absolute;
                        bottom: -4px;
                        font-size: 10px;
                        &.vcal-nights {
                            color: #444;
                            left: 1px;
                        }
                    }
                    .vcal-title {
                        font-size: 0.9rem;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 0 4%;
                        overflow: hidden;
                        cursor: pointer;
                        @media only screen {
                            color: #fff !important;
                        }
                        @media only print {
                            font-weight: bold;
                        }
                    }
                    > .vcal-events {
                        @media print {
                            display: none;
                        }
                        height: 2.0rem;
                        width: 100%;
                        text-align: center;
                        .vcal-event {
                            position: absolute;
                            text-align: center;
                            height: 2.0rem;
                            background-color: $event-bg-color;
                            //border-radius: 0.25rem;
                            @media only screen and (max-width: 767px) {
                                height: 1.5rem;
                            }
                            &.vcal-first {
                                border-left: 1px dotted $event-border-color;
                            }
                            &.vcal-last {
                                border-right: 1px dotted $event-border-color;
                            }
                            .vcal-title {
                                max-height: 2rem;
                                line-height: 2rem;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                @media only print {
                                    color: #000 !important;
                                }
                            }
                            @media only screen and (min-width:768px) and (max-width: 1023px) {
                                .vcal-time, .vcal-length {
                                    display: none;
                                }
                            }
                            @media only print {
                                background: none !important;
                                .vcal-time, .vcal-length {
                                    display: none;
                                }
                            }
                            &.holiday {
                                top: 0;
                                height: 100%;
                                color: $event-holiday-text-color;
                                background-color: $event-holiday-bg-color;
                                border-radius: 0;
                                font-weight: bold;
                                .vcal-title {
                                    top: 42%;
                                }
                                .vcal-time {
                                    top: 26%;
                                }
                                .vcal-length {
                                    bottom: 41%;
                                }
                            }
                            &.birthday {
                                background-color: $event-birthday-bg-color !important;
                            }
                            @media only screen and (max-width: 1023px) {
                                .vcal-time, .vcal-length {
                                    display: none;
                                }
                            }
                            @media only print {
                                .vcal-time, .vcal-length {
                                    display: none;
                                }
                            }
                        }
                        i {
                            margin-top: 0.2rem;
                            color: $event-bg-color;
                            &.birthday {
                                color: $event-birthday-bg-color !important;
                            }
                        }
                    }
                    .vcal-schedule {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        .vcal-child-schedule {
                            height: $schedule-height;
                            border-top: 1px solid #ccc;
                            @media only print {
                                &:first-child {
                                    border-top: none;
                                }
                            }
                            .vcal-events {
                                height: $event-height;
                                width: 100%;
                                position: relative;
                                border-bottom: 1px dotted #ccc;
                                .vcal-event {
                                    position: absolute;
                                    text-align: center;
                                    height: $event-height;
                                    background-color: $event-bg-color;
                                    //border-radius: 0.25rem;
                                    @media only screen and (max-width: 767px) {
                                        height: 1.5rem;
                                    }
                                    @media only print {
                                        background: none !important;
                                    }
                                    &.vcal-first {
                                        border-left: 1px dotted $event-border-color;
                                    }
                                    &.vcal-last {
                                        border-right: 1px dotted $event-border-color;
                                    }
                                    .vcal-title {
                                        font-size: 0.8rem;
                                        max-height: $event-height;
                                        line-height: 0.8rem;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        @media only print {
                                            font-style: italic;
                                        }
                                    }
                                    @media only screen and (min-width:768px) and (max-width: 1023px) {
                                        .vcal-time, .vcal-length {
                                            display: none;
                                        }
                                    }
                                    @media only print {
                                        .vcal-time, .vcal-length {
                                            display: none;
                                        }
                                    }
                                    &.holiday {
                                        top: 0;
                                        height: 100%;
                                        color: $event-holiday-text-color;
                                        background-color: $event-holiday-bg-color;
                                        border-radius: 0;
                                        .vcal-title {
                                            top: 50%;
                                        }
                                        .vcal-time {
                                            top: -15%;
                                        }
                                        .vcal-length {
                                            bottom: -15%;
                                        }
                                    }
                                    &.birthday {
                                        background-color: $event-birthday-bg-color !important;
                                    }
                                    @media only screen and (max-width: 1023px) {
                                        .vcal-time, .vcal-length {
                                            display: none;
                                        }
                                    }
                                    @media only print {
                                        .vcal-time, .vcal-length {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .vcal-periods {
                                height: $period-height;
                                width: 100%;
                                position: relative;
                                .vcal-period {
                                    position: absolute;
                                    text-align: center;
                                    height: $period-height;
                                    @media only screen and (max-width: 767px) {
                                        height: 2.8rem;
                                    }
                                    &.vcal-first {
                                        border-top-left-radius: 0.25rem;
                                        border-bottom-left-radius: 0.25rem;
                                        text-align: right;
                                        padding-right: 0.25em;
                                        @media only print {
                                            border-radius: 0;
                                            border: none;
                                        }
                                    }
                                    &.vcal-last {
                                        border-top-right-radius: 0.25rem;
                                        border-bottom-right-radius: 0.25rem;
                                        text-align: left;
                                        padding-left: 0.25em;
                                        @media only print {
                                            border-radius: 0;
                                            border: none;
                                        }
                                    }
                                    &.vcal-clash {
                                    }
                                    @media only screen {
                                        &.vcal-edited {
                                            border-left-width: 0;
                                            border-right-width: 0;
                                            border-bottom-width: 0;
                                            border-top-width: 1px;
                                            border-style: solid;
                                            border-color: $period-edited-text-color;
                                            &.vcal-first {
                                                //border-left-width: 2px;
                                            }
                                            &.vcal-last {
                                                //border-right-width: 2px;
                                            }
                                        }
                                    }
                                    &.vcal-drag {
                                        opacity: 0.5;
                                    }
                                    .vcal-title {
                                        max-height: 2rem;
                                        line-height: 1rem;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                    @media only screen and (min-width:768px) and (max-width: 1023px) {
                                        .vcal-time, .vcal-length {
                                            display: none;
                                        }
                                    }
                                    @media only print {
                                        background: none !important;
                                        .vcal-time, .vcal-length {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        &.vcal-num-children-2 {
                            .vcal-child-schedule {
                                &.vcal-child-schedule-1 {
                                    bottom: $schedule-height;
                                    border-bottom: 1px dotted rgba(255,255,255,0.3);
                                    .vcal-period {
                                        &.vcal-first {
                                            border-bottom-left-radius: 0;
                                        }
                                        &.vcal-last {
                                            border-bottom-right-radius: 0;
                                        }
                                    }
                                }
                                &.vcal-child-schedule-2 {
                                    bottom: 0;
                                    .vcal-period {
                                        &.vcal-first {
                                            border-top-left-radius: 0;
                                        }
                                        &.vcal-last {
                                            border-top-right-radius: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.move-top {
    z-index: 10;
    @media only print {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {
    X.ui.container {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
