.react-calendar-timeline {
    .rct-header-container {
        .rct-sidebar-header {
            #tl-controls {
                text-align: center;
                > div {
                    padding: 3px 0;
                }
            }
        }
    }
    .rct-item {
        &.family {
            background-color: purple !important;
            font-weight: bold !important;
            &.birthday {
            	background-color: $event-birthday-bg-color !important;
            }
        }
        &.school {
            &.holiday {
            	background-color: $event-holiday-bg-color !important;
			}
            &.closure {
                font-style: italic !important;
            }
        }
        &.period {
        }
        .rct-item-handler {
            cursor: col-resize !important;
        }
        .rct-item-content {
            position: relative;
            width: 100%;
            .time {
                position: absolute;
                top: -22px;
                font-size: 8px;
                &.start {
                    left: 1px;
                }
                &.end {
                    right: 1px;
                }
            }
            .length {
                position: absolute;
                bottom: -21px;
                font-size: 8px;
                &.nights {
                    left: 1px;
                }
            }
        }
    }
    .rct-vertical-lines { 
        .rct-vl.rct-day-0, .rct-vl.rct-day-6 {
            background-color: rgba(225, 200, 150, 0.5) !important;
        }
    }
}

.role-admin, .role-guest, .member-phil, .member-alexander {
    .react-calendar-timeline {
        .rct-item {
            &.period {
				&.carer-alexander {
					background-color: $period-color-own !important;
				}
				&.carer-victoria {
					background-color: $period-color-other !important;
				}
			}
		}
	}
}

.member-philX, .member-victoria {
    .react-calendar-timeline {
        .rct-item {
            &.period {
				&.carer-alexander {
					background-color: $period-color-other !important;
				}
				&.carer-victoria {
					background-color: $period-color-own !important;
				}
			}
		}
	}
}

table.agenda {
    td {
        &[rowSpan] {
            vertical-align: top;
        }
    }
}
