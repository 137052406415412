.calendar .calendar-header {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
    border: 0;
}

.calendar .calendar-header .year-title {
    font-size: 18px;
}

.calendar .calendar-header .year-title:not(.year-neighbor):not(.year-neighbor2) {
    border-bottom: 2px solid #2196f3;
}

.calendar .months-container .month-container {
    height: 252px !important;
    margin-bottom: 25px;
}

.calendar table.month {
    background-color: white;
}

.calendar table.month th.month-title {
    font-size: 18px;
}

.calendar table.month th, .calendar table.month td {
    height: 34px !important;
    width: 34px !important;
}

.calendar table.month th.day-header {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.calendar table.month td.day .day-content {
    font-size: 14px;
    padding: 8px;
    border-radius: 100%;
}

table.planner-totals {
    margin: 1.25rem auto;
    td {
        &:last-child {
            font-weight: bold;
        }
    }
    th, td {
        &.header {
            font-weight: bold;
        }
    }
}
