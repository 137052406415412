// 20 distinct colours - https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
$color-red: rgb(230, 25, 75);
$color-green: rgb(60, 180, 75);
$color-yellow: rgb(255, 225, 25);
$color-blue: rgb(0, 130, 200);
$color-orange: rgb(245, 130, 48);
$color-purple: rgb(145, 30, 180);
$color-cyan: rgb(70, 240, 240);
$color-magenta: rgb(240, 50, 230);
$color-lime: rgb(210, 245, 60);
$color-pink: rgb(250, 190, 190);
$color-teal: rgb(0, 128, 128);
$color-lavender: rgb(230, 190, 255);
$color-brown: rgb(170, 110, 40);
$color-beige: rgb(255, 250, 200);
$color-maroon: rgb(128, 0, 0);
$color-mint: rgb(170, 255, 195);
$color-olive: rgb(128, 128, 0);
$color-apricot: rgb(255, 215, 180);
$color-navy: rgb(0, 0, 128);
$color-grey: rgb(128, 128, 128);
$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);

$period-color-own: #6d9ed1;
$period-color-other: #c58bd3;
$period-edited-text-color: red;

$event-bg-color: rgba(165,42,42,0.6);
$event-birthday-bg-color: rgba(220,40,40,0.8);
$event-holiday-text-color: #444;
$event-holiday-bg-color: rgba(255,225,0,0.4);

