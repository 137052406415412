//@import "../node_modules/react-big-calendar/lib/css/react-big-calendar";
//@import "@y0c/react-datepicker/assets/styles/calendar.scss";
@import '~react-calendar-timeline/lib/Timeline';
//@import '~rc-datetime-picker/dist/picker.min';
$font-path: '~react-widgets/lib/fonts';
$img-path: '~react-widgets/lib/img';
@import '~react-widgets/lib/scss/react-widgets';

@import "palette";

$successColor: #16ab39;
$infoColor: #00b5ad;
$warningColor: #fbbd08;
$errorColor: #db2828;

body {
  section {
    margin: 8px 0;
  }
}

table {
  &.table {
    //width: unset !important;
  }
  th {
    &.sortable {
      cursor: pointer !important;
    }
    &.active {
      font-style: italic;
    }
    &:not(.active) {
      > i {
        color: #ccc;
      }
    }
  }
  td {
  }
  th, td {
    &.money {
      text-align: right !important;
    }
    &.duration {
      text-align: right !important;
    }
    &.center {
      text-align: right !important;
    }
    &.center {
      text-align: center !important;
    }
    &.right {
      text-align: right !important;
    }
    &.actions {
      text-align: center !important;
    }
    &.fit, &.actions {
      white-space: nowrap !important;
    }
    &.actions {
      > button {
        margin-left: 0.2rem !important;
      }
    }
  }
}

button {
  &.link {
    display: inline;
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 0;
    vertical-align: inherit;
    line-height: inherit;
    &:hover, &:focus {
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
}

input[type=number] {
  text-align: right !important;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.clickable {
  cursor: pointer;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.trunc4 {
    max-width: 4em;
  }
  &.trunc6 {
    max-width: 6em;
  }
  &.trunc8 {
    max-width: 8em;
  }
  &.trunc10 {
    max-width: 10em;
  }
  &.trunc12 {
    max-width: 12em;
  }
  &.trunc14 {
    max-width: 14em;
  }
  &.trunc16 {
    max-width: 16em;
  }
  &.trunc20 {
    max-width: 20em;
  }
  &.trunc24 {
    max-width: 24em;
  }
}

.date-selector {
  select, input {
    margin: 0 4px;
  }
}

input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=date] {
  -moz-appearance:textfield;
}

.DayPicker-Day {
  line-height: 1.25;
}

.DayPicker-Footer {
  text-align: center;
}

.tooltip-anchor {
  cursor: default;
}

//@import "general";
//@import "overrides";
//@import "table";
//@import "form";
@import "login";
@import "calendar";
@import "planner";
@import "timeline";
@import "account";
//@import "reports";

.ui {
    &.container {
        &.header {
            margin-top: 0;
            margin-bottom: 60px;
            @media only print {
              display: none;
            }
        }
    }
    &.segment {
        .grid {
            .totals {
                font-size: 1.1rem;
                > p {
                    display: inline;
                    color: rgba(0,0,0,0.6);
                    &:after {
                        content: ' - ';
                    }
                    &:last-child {
                        &:after {
                            content: none;
                        }
                    }
                    span {
                      font-weight: bold;
                      color: rgba(0,0,0,0.87);
                    }
                }
            }
            .filters {
                > * {
                    margin-left: 0.4rem;
                }
                .fields {
                  margin-bottom: 0;
                }
            }
        }
    }
    &.header {
      & > button {
        float: right;
        margin-top: -0.4rem;
        > i {
          opacity: 1.0 !important;
        }
      }
    }
    &.dropdown {
        .menu {
            > .item {
                font-size: inherit !important;
            }
        }
    }
    &.form {
        .field {
            &.static {
                label + span {
                    display: inline-block;
                    margin: 0.5em 0.25em 0;
                }
            }
        }
    }
}

.rw-widget {
    &.rw-datetime-picker {
        .rw-widget-picker {
            input {
                border: none !important;
            }
            .rw-select {
                .rw-btn {
                    vertical-align: baseline !important;
                    width: 1.6em !important;
                    padding-left: 2px !important;
                }
            }
        }
    }
}

$tpBg: rgba( 0,255,255,0.1);
$sbBg: rgba( 255,0,255,0.1);

.key {
    text-transform: capitalize;
    display: inline-block;
    > span {
        vertical-align: middle;
        display: inline-block;
        line-height: 2.4em;
        height: 2.4em;
        &.swatch {
            width: 2.4em;
            border: 1px solid;
            margin-right: 0.5em;
            border-radius: 3px;
        }
    }
    &.tp {
        .swatch {
            background-color: $tpBg;
        }
    }
    &.sb {
        .swatch {
            background-color: $sbBg;
        }
    }
}

table {
    &#itemsTable {
        th,td {
            &.tp {
                background-color: $tpBg;
            }
            &.sb {
                background-color: $sbBg;
            }
        }
    }
}

.screenOnly {
  @media only print {
      display: none !important;
  }
}

.printOnly {
  @media only screen {
      display: none;
  }
}
